const fetchStars = () => {
  let cachedGithubStars = localStorage.getItem('cachedGithubStars');
  document.getElementById('paperStarsCount').innerText =
    cachedGithubStars || '3000+';
  fetch('https://api.github.com/repos/callstack/react-native-paper')
    .then(res => res.json())
    .then(resJson => {
      let stars = resJson.stargazers_count;
      if (typeof stars === 'number') {
        document.getElementById('paperStarsCount').innerText = stars;
        localStorage.setItem('cachedGithubStars', stars);
      }
    })
    .catch(e => {
      return;
    });
};

export default fetchStars;
